import React from 'react'

const NotFoundPage: React.FC = () => (
  <div className="flex justify-center w-full items-center space-y-1 flex-col my-5">
    <div className="text-3xl">404</div>
    <div>Page Not Found</div>
  </div>
)

// noinspection JSUnusedGlobalSymbols (PHPStorm doesn't detect NextJS's pages)
export default NotFoundPage
